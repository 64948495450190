
const stockForecastAll = {
  url: `api/forecast/stockforecastall`,
  methodType: "POST",
  version: "1.0",
  compression: true,
}

const reservedDocuments = {
  url: `api/forecast/reserveddocuments`,
  methodType: "POST",
  version: "1.0",
  compression: true,
}

const modalDetail = {
  url: `api/forecast/modalDetail`,
  methodType: "POST",
  version: "1.0",
  compression: true,
}

const checkStockForecast = {
  url: `api/forecast/checkstockforecast`,
  methodType: "POST",
  version: "1.0",
  compresion: true
}

export const apis = {
  stockForecastAll,
  checkStockForecast,
  modalDetail,
  reservedDocuments
}