import * as React from 'react';
import { AuthenticationContext, withAdalLogin } from 'react-adal';
import {withAdalLoginApi} from 'adalConfig';
import { Route } from 'react-router-dom';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const ProtectedPage = withAdalLoginApi(Component, () => <div />, (error) => <div />);

  return (
    <Route
      {...rest}
      render={props => {
        return (
            <ProtectedPage {...props} authContext />
        )}}
    />);
}

export default RestrictedRoute;