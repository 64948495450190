import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal';

export const adalConfig = {
  tenant: '58ec973f-9c94-4e25-8019-d22e334fc973',
  clientId: '059f608c-d884-4b22-bb89-ea3ff2e57bf8',
  endpoints: {
    api: '059f608c-d884-4b22-bb89-ea3ff2e57bf8',
  },
  cacheLocation: 'localStorage',
  postLogoutRedirectUri: 'https://stockforecast.rhplus.cloud/logout'
};

export const authContext = new AuthenticationContext(adalConfig);
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
export const invalidateResourceTokens = () => {
  authContext.invalidateResourceTokens();
  console.log("Invalidated");
} 
export const authUser = () => authContext?._user?.profile || {};