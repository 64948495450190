import React, { useEffect, useReducer } from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { PageContext, usePageContext, PageReducer } from '@bit/hlouzek.rhplus.page';
import configureStore, { history } from './store';
import App from './containers/App';
import LocalIdb from './localIdb';
import CacheBuster from '@bit/hlouzek.rhplus.cache-buster';
import 'ag-grid-enterprise';
import { LicenseManager } from "ag-grid-enterprise";
import useApi, { CallApi, errorNotification } from '@bit/hlouzek.rhplus.api';
import { LoaderContainer } from '@bit/hlouzek.rhplus.loader';
import { RHPLUS_PURCHASEORDER_LOADING_CHANNEL } from './constants/PusherTypes';
import { DraggableModalProvider } from 'ant-design-draggable-modal';

// configuraton AgGrid Enterprise
LicenseManager.setLicenseKey("MTU1MzczMTIwMDAwMA==555b6946f1f4ee082a509e13fbc32c10");

// configuration Redux store
export const store = configureStore();

const Apps = (props) => {
  const initialPageContext = usePageContext();
  const [pageState, pageDispatch] = useReducer(PageReducer, initialPageContext);
  const { callApi } = useApi(process.env.REACT_APP_PROXY);
  const { open } = LocalIdb();
  const pusherConfig = require(`${process.env.REACT_APP_PUSHER}`);

  useEffect(() => {
    var store = open();
  }, []);

  return (
    <CacheBuster packageJson={import('../package.json')}>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <DraggableModalProvider>
            <LoaderContainer config={pusherConfig} channel={RHPLUS_PURCHASEORDER_LOADING_CHANNEL}>
              <PageContext.Provider value={{ pageState, pageDispatch }}>
                <App {...props} />
              </PageContext.Provider>
            </LoaderContainer>
          </DraggableModalProvider>
        );
      }}
    </CacheBuster>
  )
}

export default function MainApp() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={Apps} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  )
}

